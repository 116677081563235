<template>
  <div>
    <el-dialog
        title="提现"
        :visible.sync="dialogVisible"
        width="40%">
      <div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="账号名" prop="name">
            <el-input v-model="ruleForm.name" placeholder="请输入用户名"></el-input>
          </el-form-item>
          <el-form-item label="银行账号" prop="region">
            <el-input v-model="ruleForm.name" placeholder="请输入银行账号"></el-input>

          </el-form-item>
          <el-form-item label="开户支行名称" prop="delivery">
            <el-input v-model="ruleForm.name" placeholder="请输入开户支行名称"></el-input>
          </el-form-item>
          <el-form-item label="提现金额" prop="delivery">
            <el-input-number :min="1" :max="10" placeholder="提现金额"></el-input-number>
            <span style="padding-left: 10px">可提现金额：</span>
            <span style="color: #ff8143">1000</span>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="getConfirm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "withdrawCashDialog",
  props: {
    dialogVisible: {
      type: Boolean,
      default: ()=> {
        return true;
      }
    }
  },
  data() {
    return {
      ruleForm: {
        name: '',
        visible: true,
      },
      rules: {
        name: [
          {required: true, message: '请输入用户名', trigger: 'blur'},
        ]
      },
    }
  },
  methods: {
    getConfirm() {
      // this.visible = false;
    },
  }
}
</script>

<style scoped>

</style>