<template>
  <div class="content" v-loading="loadingStatus.getTable">
    <div>
      <el-form
        ref="staffRef"
        :inline="true"
        size="medium"
        :model="dataForm"
        label-width="90px"
      >
        <!--        <el-form-item label="会长名称：">
          <el-input placeholder="请输入会长名称"></el-input>
        </el-form-item>
        <el-button type="primary" size="medium">查询</el-button>
        <el-button size="medium" plain>重置</el-button>-->
        <!--        :disabled="tableData.length > 0 ? false : true"-->
        <el-button
          type="primary"
          @click="getCommission"
          size="medium"
          style="margin-bottom: 20px"
          >提现</el-button
        >
        <!--        <el-button type="warning" size="medium">导出</el-button>-->
      </el-form>
    </div>
    <div>
      <el-table :data="tableData" border :stripe="true" style="width: 100%">
        <el-table-column prop="date" label="编号" align="center" width="80">
          <template slot-scope="scope">
            <span>{{
              scope.$index + 1 + (page.pageNo - 1) * page.pageSize
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="presidentName"
          label="会长名称"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="bankAccountName"
          width="140px"
          label="账号名"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="payeeAccount"
          width="180px"
          label="银行账号"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="alipayAccount"
          width="180px"
          label="支付宝账号"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="alipayName"
          width="180px"
          label="支付宝用户姓名"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="outcomeAccount"
          label="提现金额"
          width="140px"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="status"
          width="120px"
          label="提现状态"
          align="center"
        >
          <template slot-scope="scope">
            <!--            <span>{{scope.row.status}}</span>-->
            <!--            1申请中/2已驳回/3已处理/4取消申请-->
            <el-tag v-if="scope.row.status == 0">申请中</el-tag>
            <el-tag v-else-if="scope.row.status == 1" type="success"
              >已处理</el-tag
            >
            <el-tag v-else-if="scope.row.status == 2" type="warning"
              >已驳回</el-tag
            >
            <el-tag v-else type="info">已取消</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="outcomeDatetime"
          width="160px"
          label="申请时间"
          align="center"
        ></el-table-column>
        <!--        <el-table-column prop="date" label="处理时间" align="center"></el-table-column>-->
        <el-table-column label="操作" width="180px" align="center">
          <template slot-scope="scope">
            <!--            <span style="cursor: pointer;color: #1D90FF;padding-right: 10px">处理(平台)</span>-->
            <!--            <span style="cursor: pointer;color: #1D90FF;padding-right: 10px">驳回(平台)</span>-->
            <span
              v-if="scope.row.status == 0"
              style="cursor: pointer;color: #1D90FF;"
              @click="getDealWithReject(scope.row)"
              >取消</span
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="page"
        background
        @size-change="handleSizeChange"
        @current-change="currentChange"
        :page-sizes="[10, 15, 20]"
        :page-size="1"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.total"
        :current-page="page.pageNo"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="提现"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="40%"
    >
      <div>
        <el-form
          :model="formData"
          :rules="rules"
          ref="rulesForm"
          label-width="110px"
          class="demo-ruleForm"
        >
          <el-form-item label="账号类型：">
            <el-radio-group v-model="radio" @change="getBankRadio">
              <el-radio :label="1">银行卡</el-radio>
              <el-radio :label="2">支付宝</el-radio>
            </el-radio-group>
          </el-form-item>

          <span v-if="radio == 1 && this.bankData != null">
            <el-form-item label="账号名：">
              <el-input
                v-model="bankData.bankAccountName"
                disabled="disabled"
                placeholder="请输入账号名"
              ></el-input>
            </el-form-item>
            <el-form-item label="银行卡号：">
              <el-input
                v-model="bankData.bankAccountNo"
                disabled="disabled"
                placeholder="请输入银行卡号"
              ></el-input>
            </el-form-item>
            <el-form-item label="真实姓名：">
              <el-input
                v-model="bankData.payeeName"
                disabled="disabled"
                placeholder="请输入真实姓名"
              ></el-input>
            </el-form-item>
            <!--            <el-form-item label="手机号：">-->
            <!--              <el-input v-model="bankData.name" placeholder="请输入手机号"></el-input>-->
            <!--            </el-form-item>-->
          </span>
          <span v-if="radio == 2 && this.alipayData != null">
            <!--            <el-form-item label="账号名：">-->
            <!--              <el-input v-model="alipayData.alipayAccount" placeholder="请输入账号名"></el-input>-->
            <!--            </el-form-item>-->
            <el-form-item label="支付宝账号：">
              <el-input
                v-model="alipayData.alipayAccount"
                disabled="disabled"
                placeholder="请输入支付宝账号"
              ></el-input>
            </el-form-item>
            <el-form-item label="真实姓名：">
              <el-input
                v-model="alipayData.alipayName"
                disabled="disabled"
                placeholder="请输入真实姓名"
              ></el-input>
            </el-form-item>
            <!--            <el-form-item label="手机号：">-->
            <!--              <el-input v-model="formData.name" placeholder="请输入手机号"></el-input>-->
            <!--            </el-form-item>-->
          </span>
          <el-form-item
            v-if="radio == 1 && this.bankData != null"
            label="提现金额："
            prop="outcomeAccount"
          >
            <div style="display: flex;">
              <el-input
                v-model="formData.outcomeAccount"
                placeholder="提现金额"
              ></el-input>
              <div style="width: 270px;padding-left: 20px;">
                <span style="padding-left: 10px">可提现金额：</span>
                <span style="color: #ff8143">{{ num }}</span>
              </div>
            </div>
          </el-form-item>
          <el-form-item
            v-if="radio == 2 && this.alipayData != null"
            label="提现金额："
            prop="outcomeAccount"
          >
            <div style="display: flex;">
              <el-input
                v-model="formData.outcomeAccount"
                placeholder="提现金额"
              ></el-input>
              <div style="width: 270px;padding-left: 20px;">
                <span style="padding-left: 10px">可提现金额：</span>
                <span style="color: #ff8143">{{ num }}</span>
              </div>
            </div>
          </el-form-item>
          <div v-if="type == true">
            <span style="color: red">请先到【提现账号】添加</span
            ><router-link to="/withdrawAccount"
              ><span style="color: #1D90FF;cursor: pointer;"
                >银行账号或者支付宝账号</span
              ></router-link
            >
          </div>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          :loading="loadingStatus.getSubmit"
          :disabled="type"
          type="primary"
          @click="getSubmit"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import loadingStatusMixin from '@/mixins/loadingStatus'
import withdrawCashDialog from './components/withdrawCashDialog'
import {
  settlementAdminList,
  settlementAdminAccountAdmin,
  accountAdminBankAccountAdmin,
  accountAdminAvailableBalance,
  settlementAdminPageOperation,
  settlementAdminWithdrawDeposit,
  alipayAccountAdminInfo,
  bankAccountAdminInfo,
} from '../../service/moudules/promotionLinkApi'
export default {
  name: 'staffPromotion',
  mixins: [loadingStatusMixin],
  components: { withdrawCashDialog },
  data() {
    return {
      dataForm: {
        name: '',
      },
      radio: 1,
      tableData: [],
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      dialogVisible: false,
      formData: {
        outcomeAccount: null,
      },
      rules: {
        outcomeAccount: [
          {
            required: true,
            validator: (rule, value, callBack) => {
              let test = /^(?!0+(?:\.0+)?$)(?:[1-9]\d*|0)(?:\.\d{1,2})?$/
              if (!test.test(value)) {
                // this.msgSrv.error("请输入正数,最大小数点后两位！");
                callBack(new Error('请输入正数,最大小数点后两位！'))
              } else if (value < 100) {
                callBack(new Error('提现金额需大于等于100！'))
              } else if (value > this.num) {
                callBack(new Error('提现金额不能大于可提金额！'))
              } else {
                callBack()
              }
            },
            trigger: 'blur',
          },
        ],
      },
      alipayData: {}, //支付宝
      bankData: {}, // 银行卡
      alipayBank: [],
      num: 0,
      type: false,
    }
  },
  mounted() {
    this.getTable()
    this.getBalance()
    this.getAccount()
  },
  methods: {
    async getAccount() {
      // let res = await settlementAdminAccountAdmin();
      let res = await alipayAccountAdminInfo()
      if (res.code == 200) {
        this.alipayData = res.data
      }
      let res2 = await bankAccountAdminInfo()
      if (res2.code == 200) {
        this.bankData = res2.data
      }
      this.getBankRadio()

      console.log('xddd:', res, res2)
      // if (res.code == 200) {
      //   let data = res.data;
      //   this.alipayBank = data;

      //   if (data.length > 0 && data !=null) {
      //     data.map((item) => {
      //       if (item.accountId != null) {
      //         this.bankData = { ... item}
      //       } else {
      //         this.alipayData = { ...item}
      //       }
      //     })
      //   }
      //   this.getBankRadio();

      // }
    },
    getBankRadio() {
      if (this.radio == 1) {
        if (this.bankData == null) {
          this.type = true
        } else {
          this.type = false
        }
      } else {
        if (this.alipayData == null) {
          this.type = true
        } else {
          this.type = false
        }
      }
    },
    async getBalance() {
      let res = await accountAdminAvailableBalance()
      console.log('额度：', res)
      if (res.code == 200) {
        this.num = Number(res.data)
      }
    },
    async getTable() {
      if (this.hasLoadingState('getTable')) return
      try {
        this.addLoadingState('getTable')
        let params = {
          pageNo: this.page.pageNo,
          pageSize: this.page.pageSize,
        }
        // const searchFormValue = this.dataForm;
        // Object.keys(searchFormValue).map((key) => {
        //   searchFormValue[key] && (params[key] = searchFormValue[key]);
        // });
        let res = await settlementAdminList(params)
        this.removeLoadingState('getTable')
        if (res.code == 200) {
          this.tableData = res.data.records
          // this.page.pageNo = res.data.pages;
          this.page.pageNo = res.data.current
          this.page.total = res.data.total
        } else {
          this.removeLoadingState('getTable')
          this.$message.error(`${res.msg}！`)
        }
      } catch (e) {
        this.removeLoadingState('getTable')
      }
    },
    handleSizeChange(val) {
      this.page.pageSize = val
      this.page.pageNo = 1
      this.getTable()
    },
    currentChange(pageNo) {
      this.page.pageNo = pageNo
      this.getTable()
    },
    getCommission() {
      this.dialogVisible = true
      this.formData.outcomeAccount = null
      this.getBalance()
    },
    getSubmit() {
      this.$refs.rulesForm.validate(async (valid) => {
        if (this.hasLoadingState('getSubmit')) return
        if (valid) {
          try {
            const payData = this.alipayData || this.bankData
            const { presidentId, presidentName } = payData
            let params = {
              ...this.formData,
              presidentId,
              presidentName
            }
            if (this.radio === 1) {
              let bankForm = {
                typeId: this.bankData.accountId, // 类型ID：支付宝传：alipayAccountId 银行卡传：accountId
                type: 1, //帐号类型，1->银行卡 , 2 -> 支付宝
              }
              Object.keys(bankForm).map((key) => {
                bankForm[key] && (params[key] = bankForm[key])
              })
            }
            if (this.radio === 2) {
              let alipayForm = {
                typeId: this.alipayData.alipayAccountId, // 类型ID：支付宝传：alipayAccountId 银行卡传：accountId
                type: 2, //帐号类型，1->银行卡 , 2 -> 支付宝
              }
              Object.keys(alipayForm).map((key) => {
                alipayForm[key] && (params[key] = alipayForm[key])
              })
            }
            let res = await settlementAdminWithdrawDeposit(params)
            this.removeLoadingState('getSubmit')
            if (res.code === 200) {
              this.dialogVisible = false
              this.getTable()
              this.$message.success(`提现成功！`)
            } else {
              this.$message.error(`${res.msg}！`)
            }
          } catch (e) {
            this.removeLoadingState('getSubmit')
          }
        } else {
          console.log('error submit!!')
          this.removeLoadingState('getSubmit')
          return false
        }
      })
    },
    getDealWithReject(row) {
      this.$confirm(`是否取消该数据?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        let params = {
          presidentId: row.presidentId,
          flag: 3,
          outcomeId: row.outcomeId,
        }
        let res = await settlementAdminPageOperation(params)
        if (res.code == 200) {
          this.$message({
            type: 'success',
            message: `取消成功!`,
          })
          this.getTable()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
  },
}
</script>

<style scoped lang="less">
.content {
  padding: 20px;
  margin: 10px;
  border: 1px solid #dcdfe6;
  text-align: initial;
  background-color: white;
  .con-tab {
    background-color: #e6a23c2b;
    padding: 10px 8px;
    border-radius: 8px;
    font-size: 14px;
    color: #e6a23c;
    margin-bottom: 20px;
  }
  .page {
    text-align: right;
    margin-top: 15px;
  }
  .span-cursor {
    cursor: pointer;
    padding-right: 10px;
  }
  ::v-deep .el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar {
    width: 1px;
    height: 10px;
  }
  ::v-deep
    .el-table--scrollable-x
    .el-table__body-wrapper::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.2);
  }
  ::v-deep
    .el-table--scrollable-x
    .el-table__body-wrapper::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.1);
  }
  ::v-deep .el-table__header th.el-table__cell {
    height: 50px;
    background-color: #fafafa;
    color: #333333;
    padding: 0;
  }
  ::v-deep .el-input.is-disabled .el-input__inner {
    color: #1a1a1a;
  }
}
</style>
